.App {
    margin-left: 1em;
    margin-top: 1em;
    font-family: monospace;
    font-size: 1.9vw;
  /*text-align: center; */


}

/*
  .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/
